<template>
  <div id="app">
    <Header :hideToggle="false"/>
    <Nav/>

    <!-- se o menu != visivel, a classe .hide-menu é aplicada -->

    <Content :class="{'lock-scroll': isMenuVisible}"/>
    <Footer :class="{'lock-scroll': isMenuVisible}"/>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Header from '@/components/template/HeaderComponent'
import Nav from '@/components/template/NavComponent'
import Content from '@/components/template/ContentComponent'
import Footer from '@/components/template/FooterComponent'

export default {
  name: 'App',
  components: { Header, Nav, Content, Footer },
  computed:  mapState(['isMenuVisible'])
}
</script>

<style lang="scss">
@import "./styles/helpers/reset";
@import "./styles/helpers/classes";

#app {
  overflow: hidden;
}

@media only screen and (max-width: 900px) {
  .lock-scroll {
    display: none;
  }
} /* mobile */

#colorBlindSVG { display: none; } // remove o espaço abaixo do footer
</style>
